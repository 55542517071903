<template>
  <div class="ui-tooltip" @mouseenter="showContent" @mouseleave="hideContent">
    <slot name="content" />

    <div v-if="isOpened" class="ui-tooltip__content">
      <span class="ui-tooltip__font ui-tooltip__font--text">
        <slot />
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiTooltip",
  data() {
    return {
      isOpened: false,
    };
  },
  methods: {
    showContent() {
      this.isOpened = true;
    },

    hideContent() {
      this.isOpened = false;
    },
  },
};
</script>

<style scoped lang="scss">
.ui-tooltip {
  position: relative;
  cursor: pointer;

  &__content {
    position: absolute;
    background-color: $color-blue;
    color: $color-white;
    left: 50%;
    transform: translateX(-50%);
    bottom: calc(100% + 12px);
    width: 100%;
    padding: 12px;
    text-align: left;
    border-radius: 12px;

    &::after {
      content: "";
      width: 15px;
      height: 8px;
      background-image: url("/images/icons/tooltip-angle.svg");
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;
      bottom: -8px;
      right: 60px;
    }
  }

  &__font {
    &--text {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }
  }
}
</style>
