<template>
  <LayoutPopupSkeleton class="info-popup">
    <PopupsInfoBlock
      :title="title"
      :total="total"
      :categories="categories"
      :text="text"
      :theme="theme"
    />
  </LayoutPopupSkeleton>
</template>

<script>
import LayoutPopupSkeleton from "@/components/layout/PopupSkeleton.vue";
import PopupsInfoBlock from "@/components/popups/blocks/PopupsInfoBlock.vue";

export default {
  name: "InfoPopup",
  components: { PopupsInfoBlock, LayoutPopupSkeleton },
  props: {
    title: {
      type: String,
      default: undefined,
    },
    text: {
      type: String,
      default: undefined,
    },
    total: {
      type: String,
      default: undefined,
    },
    categories: {
      type: Array,
      default: () => [],
    },
    theme: {
      type: String,
      default: undefined,
    },
  },
};
</script>
