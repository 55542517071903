<template>
  <div class="popups-info-block" :class="`popups-info-block--theme--${theme}`">
    <div class="popups-info-block__title">
      <span
        class="popups-info-block__font popups-info-block__font--title"
        v-html="title"
      />
    </div>

    <div class="popups-info-block__text">
      <span
        class="popups-info-block__font popups-info-block__font--text"
        v-html="text"
      />
    </div>

    <div class="popups-info-block__categories">
      <UiTooltip
        v-for="(category, index) in categories"
        :key="index"
        class="popups-info-block__category"
      >
        <template #content>
          <div class="popups-info-block__category-name">
            <span
              class="popups-info-block__font popups-info-block__font--category-name"
              v-html="category.name"
            />

            <IconsInfo class="popups-info-block__icon" />
          </div>

          <div class="popups-info-block__category-value">
            <span
              class="popups-info-block__font popups-info-block__font--category-value"
            >
              {{ formatCurrency(category.value) }}
            </span>
          </div>
        </template>

        {{ category.tooltip }}
      </UiTooltip>
    </div>

    <div class="popups-info-block__total">
      <div class="popups-info-block__total-title">
        <span
          class="popups-info-block__font popups-info-block__font--total-title"
          v-html="total.name"
        />
      </div>

      <div class="popups-info-block__total-value">
        <span
          class="popups-info-block__font popups-info-block__font--total-value"
        >
          {{ formatCurrency(total.value) }}
        </span>
      </div>
    </div>

    <button class="popups-info-block__button" @click="closePopup">
      <span class="popups-info-block__font popups-info-block__font--button">
        {{ state.app.locale.redirectPage.understandable }}
      </span>
    </button>
  </div>
</template>

<script>
import UiTooltip from "@/components/UI/Tooltip.vue";
import { useState } from "@/App.store";
import formatCurrency from "../../../utils/helpers/formatCurrency";
import IconsInfo from "@/components/icons/IconsInfo.vue";

export default {
  name: "PopupsInfoBlock",
  components: { IconsInfo, UiTooltip },
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: "blue",
      validator(value) {
        return ["blue", "green"].includes(value);
      },
    },
    categories: {
      type: Array,
      required: true,
    },
    total: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const state = useState();
    return {
      state,
    };
  },
  methods: {
    formatCurrency,
    closePopup() {
      this.$popup.close();
    },
    showTooltip(index) {
      this.$refs.tooltip[index].showContent();
    },
    hideTooltip(index) {
      this.$refs.tooltip[index].hideContent();
    },
  },
};
</script>

<style scoped lang="scss">
.popups-info-block {
  $parent: &;

  &__title {
    color: $color-dark;
    margin-bottom: 16px;
  }

  &__text {
    color: $color-dark-yellow;
    margin-bottom: 16px;
  }

  &__categories {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    padding: 12px 0;
    border-radius: 16px;
    margin-bottom: 8px;
  }

  &__category {
    width: 100%;
    padding: 0 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  &__icon {
    margin-left: 4px;
    width: 12px;
    height: 12px;
  }

  &__category-name {
    display: flex;
    text-align: center;
    align-items: center;
    margin-bottom: 6px;
  }

  &__total {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 16px;
    margin-bottom: 24px;
    padding: 12px 24px;
    gap: 6px;
  }

  &__button {
    margin: 0 auto;
    text-decoration: underline;
    color: $color-dark;
    background-color: transparent;
  }

  &__font {
    &--title {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 21px;
      font-weight: 600;
      line-height: 24px;
    }

    &--text {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }

    &--category-name,
    &--total-title {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      line-height: 18px;
    }

    &--category-value,
    &--total-value {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
    }

    &--button {
      font-family: "GT Walsheim Pro", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
    }
  }

  &--theme {
    &--blue {
      #{$parent} {
        &__categories {
          background-color: $color-light-purple;
          color: $color-blue;
        }

        &__total {
          color: $color-light-purple;
          background-color: $color-blue;
        }
      }
    }

    &--green {
      #{$parent} {
        &__categories {
          background-color: $color-light-green;
          color: $color-dark-green;
        }

        &__total {
          color: $color-light-green;
          background-color: $color-dark-green;
        }
      }
    }
  }
}
</style>
