<template>
  <svg
    viewBox="0 0 13 13"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 1.625C8.23438 1.625 9.82812 2.5625 10.7188 4.0625C11.5859 5.58594 11.5859 7.4375 10.7188 8.9375C9.82812 10.4609 8.23438 11.375 6.5 11.375C4.74219 11.375 3.14844 10.4609 2.25781 8.9375C1.39062 7.4375 1.39062 5.58594 2.25781 4.0625C3.14844 2.5625 4.74219 1.625 6.5 1.625ZM6.5 12.5C8.63281 12.5 10.6016 11.375 11.6797 9.5C12.7578 7.64844 12.7578 5.375 11.6797 3.5C10.6016 1.64844 8.63281 0.5 6.5 0.5C4.34375 0.5 2.375 1.64844 1.29688 3.5C0.21875 5.375 0.21875 7.64844 1.29688 9.5C2.375 11.375 4.34375 12.5 6.5 12.5ZM5.5625 8.375C5.23438 8.375 5 8.63281 5 8.9375C5 9.26562 5.23438 9.5 5.5625 9.5H7.4375C7.74219 9.5 8 9.26562 8 8.9375C8 8.63281 7.74219 8.375 7.4375 8.375H7.25V6.3125C7.25 6.00781 6.99219 5.75 6.6875 5.75H5.5625C5.23438 5.75 5 6.00781 5 6.3125C5 6.64062 5.23438 6.875 5.5625 6.875H6.125V8.375H5.5625ZM6.5 5C6.89844 5 7.25 4.67188 7.25 4.25C7.25 3.85156 6.89844 3.5 6.5 3.5C6.07812 3.5 5.75 3.85156 5.75 4.25C5.75 4.67188 6.07812 5 6.5 5Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "IconsInfo",
};
</script>
